html, body, div, span, h1, h2, h3, h4, h5, h6, p, a, dl, dt, dd, ol, ul, li,table, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, footer, header, hgroup, section, input, button{
  margin: 0;
  padding: 0;
  border: 0;
}
body, html {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
  background: #fff;
  line-height: 1 !important;
  /* -webkit-user-select: none; 
  -moz-user-select:none;  
  -o-user-select:none;  
  -ms-user-select:none; */
}
h1, h2, h3, h4, h5, h6, th {
  font-weight: normal;
}
h1, h2, h3, h4, h5, h6 {
  margin: 0;
}
p{
  margin: 0
}
a, a:hover { text-decoration: none;}
ol, ul {list-style: none;}
table {
  border-spacing: 0;
}
img{
  border: none;
}
#root{
  position: relative;
  min-height: 100%;
  background: #fff;
}

/*自定义滚动条的样式*/
div::-webkit-scrollbar{
  width: 8px;
  height: 8px;
}
div::-webkit-scrollbar-track{
  /* background: rgb(239, 239, 239); */
  background:rgb(245,245,245);
  border-radius:2px;
}
div::-webkit-scrollbar-thumb{
  background: #bfbfbf;
  border-radius:10px;
}
div::-webkit-scrollbar-thumb:hover{
  background: rgb(87, 147, 252);
}
div::-webkit-scrollbar-corner{
  background: #3D81FA;
}
.clear-fix:before,
.clear-fix:after {
  content: '';
  display: table;
}

.clear-fix:after {
  clear: both;
}
.clearfix:before,
.clearfix:after {
  content: '';
  display: table;
}

.clearfix:after {
  clear: both;
}

.main{
  margin-top: 60px;
  min-height: calc(100vh - 60px);
}
.flex-center{
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-start{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.flex-between{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-around {
  display: flex;
  justify-content: space-around;
  align-items: center;

}
.flex-column-between{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.flex-vertical-center{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.nowrap{
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}

.w1200{
  min-width: 1200px;
  width: 1200px;
  margin: 0 auto !important;
}
.paddingTop-88{
  padding-top: 88px;
}

.w750{
  max-width: 750px;
  min-width: 320px;
  margin: 0 auto !important;
}

.ant-layout {
  background: #fff !important;
}



h2.con-title{
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  color: #252B3A;
  line-height: 45px;
}
p.con-caption{
  max-width: 835px;
  font-size: 16px;
  font-weight: 400;
  color: #575D6C;
  line-height: 30px;
}

@media (max-width: 768px) {
  .w1200{
    width: 100%;
  }
  .paddingTop-88{
    padding-top: 32px;
  }
  .ant-back-top {
    bottom: 60px !important;
    right: 16px !important;
  }
  .split-line{
    display: none;
  }
  .scrollTop {
    width: 34px;
    height: 34px;
    border-radius: 4px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 12px 0 rgba(0, 0, 0, 0.08), 0 4px 28px 6px rgba(0, 0, 0, 0.05);
  }
  h2.con-title{
    font-size: 26px;
    font-weight: 500;
    color: #252B3A;
  }
  p.con-caption{
    max-width: 100%;
    font-size: 16px;
    font-weight: 400;
    color: #575D6C;
  }
}



